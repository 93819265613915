*{
    padding: 0;
    margin: 0;
    
}

.SectionContainer{
    color: black;
    padding-bottom: 5%;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.SectionContainer h1{
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    color: rgb(16, 175, 159);
    margin-bottom: 2%;
    font-size: 60px;
    font-family: 'Open Sans';
    font-weight: normal;
}

.SectionContainer hr{
        display: block;
        margin-right: auto;
        margin-left: auto;
        width:56%;
        position: relative;
        color: gray;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: 1%;
    
}


#Profile{
    /* background-color: pink; */
    background-color: white;
    /* height: 91vh; */
}

#Experiences{
    /* background-color: #ffefcc; */
    background-color: #C0C0C0;
    /* height: 220vh; */
}

#Skills{
    background-color: white;
    /* height: 105vh; */
   
}

#Projects{
    /* background-color: #ffefcc; */
    background-color: lightgray;
}

#Contact{
    background-color:#4d4d4d ;
    /* height: 40vh; */
}

#ImageCompare{
    background-color: orange;
}


@media screen and (max-width: 768px) {
    .SectionContainer { 
        width: 100%;
        padding: 2%;
    } 
    #projects{
        height: 200vh;
    }
    #experiences{
        height: 100%;
    }
    #profile{
        height: 100vh;
    }
    #skills{
        height:100%;
    }

    h5{
        margin-left:5%;
        margin-right:5%;
    }
    
}

