.container{
    background-image: url('./background.jpg');
    /* background-image: url('./black.gif'); */
    /* position: relative; */
    height: 100vh;
    width: 100%;
    background-size: 100% 100%;
   background-repeat: no-repeat;
   align-items: center; 
   justify-items: center;
}

.container h1{
    color: white;
    position: relative;
    top: 40%;
    /* margin-top: 30%; */
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 50px;;
    
}
.container hr{
    display: block;
    margin-right: auto;
    margin-left: auto;
    width:30%;
    position: relative;
    top: 40vh;
    color: white;
    align-items: center;
    justify-content: center;
    display: flex;

}

.container h2{
    color: white;
    position: relative;
    top: 40%;
    align-items: center;
    justify-content: center;
    display: flex;
}

@media (max-width: 768px) {
    .container { 
        background-image: none;
        background-color: black;
        
    } 
    h2{
        font-size: 20px;
    }
}
