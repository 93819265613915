.nav {
  background-color: rgb(46, 46, 46);
  position: fixed;
  top: 20%;
  right: 1%;
  width: 120px;
  z-index: 1;
  overflow-x: hidden;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid rgb(255, 255, 255);
  border-radius: 0%;
  align-items: center;
}

.nav-item {
  position: relative;
  padding-top: 30%;
  height: 60px;
  display: block;
  color: rgb(255, 255, 255);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.nav .nav-content {
  align-items: center;
}

.nav-item:hover {
  color: rgb(141, 141, 141);
  cursor: pointer;
}

.nav-item:active {
  font-size: large;
}

.active .nav-item {
  /* border: 1px solid black; */
  background-color:rgb(16, 175, 159);
}

@media screen and (max-width: 768px) {
  .nav{
    display: none;
  }
}

