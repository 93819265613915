@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100&family=Open+Sans:wght@300&display=swap');
.contactContainer{
    /* background-color: blue; */
    margin: 15px;
    display: flex;
    width: 60%;
    margin-left: 25%;
    position: relative;
    color: lightgrey;
}

#contact{
    color: lightgrey;
}
.contactColumn{
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    align-items: center; 
    justify-items: center;
   
}
.contactLeft{
    /* background-color: white; */
    width: 90%;
    height: 30vh;
}

.contactRight{
    position: relative;
    /* background-color: orange; */
    height: 100px;
    width: 90%;
    height: 30vh;
}

.contactContainer ul{
    font-family: 'Barlow';
    display: column;
    margin-top: 10%;
    padding-left: 0%;
    list-style-type: none;
    
}

.contactContainer li{
    padding-top: 1%;
    padding-bottom: 1%;
    display: flex;
    /* background-color: aqua; */
    margin-bottom: 5%;
    /* float: ; */
}
@media screen and (max-width: 768px) {
    .contactContainer{
        width:100%;
        margin-left: 15%;
        display: block;
    
    }
    /* .contactContainer ul{
        display: flex;
        flex-wrap: wrap;
    } */
    .contactColumn{
        display: block;
    }
    .contactLeft{
        width: 100%;
        height: 50%;
    }
    .contactRight{
        width: 100%;
        height: 50%;
    }
    .contactContainer ul{
        margin-top: 0;
    }
  
}

