@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100&family=Open+Sans:wght@300&display=swap');

.projectsContainer {
    /* background-color: blue; */
    margin: 15px;
    width: 60%;
    color: lightgrey;
    display: grid;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 15px;
}

.box {
    background-color: rgb(99, 95, 87);
    height: 220px;
    /* width: 200px; */
    border-radius: 3px;
    position: relative;
    top: 0;
    transition: top ease 0.3s;
}

.box:hover {
    top: -10px;
}

.box:hover h3 {
    color: white;
}

.box:hover p {
    color: white;
}

.box:hover li {
    color: white;
}

.box:hover .folderIcon {
    color: white;
}

.box a {
    position: relative;
    left: 80%;
    top: -18%;
}

.box a:hover {
    color: white;
}

.box h3 {
    margin-left: 5%;
    margin-top: -25%;
    font-weight: 900;
}

.box p {
    margin-left: 5%;
    margin-top: 15%;
    font-size: smaller;
    margin-right: 2%;
    font-weight: bold;
}

.box ul {
    list-style-type: none;
    position: absolute;
    left: 1vw;
    /* top: 1vw; */
    margin-top: 3%;
    bottom: 2%;
    width: 100%;
}

.box li {
    display: inline-block;
    margin-right: 5%;
    font-family: 'Courier New', Courier, monospace;
}

.Note {
    position: relative;
    /* justify-content: center; */
    margin: auto;
    width: 50%;
    top: 10%;
}

.featuredProjectContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    padding: 20px 0px;

}

.featuredProject {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    background-color: rgb(235, 235, 235);
    border-radius: 30px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    width: 70%;
}

.featuredImage img {
    display: flex;
    max-width: 15vw;
    height: 0%;
    border-radius: 20px;
    transition: transform 0.3s;
    /* Add transition for smooth expansion */
}


.featuredDescription {
    flex: 2;
    padding: 0 30px;
    display: inline-block;
}


.featuredDescription h1 {
    font-size: 30px;
    padding: 5px;
    background-color: rgb(163, 5, 163);
    text-align: left;
    color: white;
    border-radius: 2px;

}

.featuredDescription h2 {
    color: rgb(95, 95, 95);
    text-align: right;
}

.featuredDescription a {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: rgb(163, 5, 163);
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.featuredDescription a:hover {
    background-color: rgb(114, 8, 114);
}

.featuredDescription p {
    padding-bottom: 6px;
}




.featuredBajaDescription {
    flex: 2;
    padding: 0 30px;
    display: inline-block;
}

.featuredBajaDescription h1 {
    font-size: 30px;
    padding: 5px;
    background-color: rgb(153, 8, 8);
    text-align: left;
    color: white;
    border-radius: 2px;

}

.featuredBajaDescription h2 {
    color: rgb(95, 95, 95);
    text-align: right;
}

.featuredBajaDescription a {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color:rgb(153, 8, 8);
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.featuredBajaDescription a:hover {
    background-color: rgb(117, 4, 4);
}

.featuredBajaDescription p {
    padding-bottom: 6px;
}