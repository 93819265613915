@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100&family=Open+Sans:wght@300&display=swap');
.profileContainer{
    /* background-color: blue; */
    margin: 15px;
    display: flex;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    /* padding-bottom: 10%; */
    height: 100%;
}

.profileColumn{
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    align-items: center; 
    justify-items: center;
   
}


.profileLeft{
    /* background-color: white; */
    width: 95%;
    height: 50vh;
 
}

.profileMiddle{
    position: relative;
    /* background-color: orange; */
    height: 100px;
    width: 90%;
    height: 50vh;
    align-items: center; 
    justify-items: center;
    
}

.profileRight{
    /* background-color: green; */
    height: 100px;
    width: 90%;
    height: 50vh;
    margin-left: 1%;
}

.profileColumn h2{
    color: black;
    font-size: 30px;
    font-family: 'Open Sans';
    font-weight: bold;
    margin-top: 2%;
    margin-left: 2%;
    justify-content: left;
    align-items: left;
}

.profileColumn p{
    position: relative;
    color: black;
    display: flex;
    font-family: Barlow;
    font-weight: bold;
    margin-top: 2%;
    margin-left: 2%;
    margin-bottom: 5%;
    font-size: 18px;
}

.profilePicture {
    width: auto;
    height: auto;  /* Allow the height to adjust based on content */
    margin: 2%;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 20%;
    background-size: cover;  /* Ensure the image covers the area */
    background-image: url('./headshot.png');
    background-repeat: no-repeat;
    border-radius: 50%;  /* If you want a circular image */
 }
 
.profileColumn h3{
    position: relative;
    top: 0%;
    font-family: 'Open Sans';
    margin-left: 2%;
    font-weight: bold;
}

.profileMiddle img {
    width: auto;  /* Adjust width based on aspect ratio */
    height: 80%;  /* Adjust the height as per your design needs */
    margin: 0%;
    margin-left: -5%;
    margin-right: 0%;
    margin-top: 20%;
    object-fit: contain;  /* Ensures the image maintains its aspect ratio and fits in the container */
    /*border-radius: 50%;  /* Optional: Makes the image circular */
}


.resumeContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.resumeLink a {

    display: flex;
    padding: 10px 20px;
    background-color: rgb(16, 175, 159);
    color: #fff;
    text-decoration: none;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  
  .resumeLink a:hover {
    background-color: rgb(10, 126, 114);
  }
    


@media screen and (max-width: 863px) {

   
    .profilePicture{
        display: none;
        width: 0;
    }
    .profileContainer{
        height: auto;
        width: 100%;
    }
    .profileMiddle{
        width: 0;
    }
    .profileLeft{
        width: 60vw;
        margin-right: 0;
        margin-left:  8%;
        height: auto;
    }
    .profileRight{
        margin-left: 0;
        margin-right: 10%;
        position: relative;
        left: -20%;
        height: auto;
    }
    

}
@media screen and (max-width: 1300px) and (min-width: 863px) {
    .lastSec{
      display: none;
    }
  
  }
