@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100&family=Open+Sans:wght@300&display=swap');
.skillsContainer{
    /* background-color: blue; */
    margin: 15px;
    display: flex;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    
}

.skillsColumn{
    flex: 1;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    align-items: center; 
    justify-items: center;
    margin-bottom: 3%;
   
}


.skillsLeft{
    /* background-color: white; */
    width: 95%;
    /* height: 10vh; */
 
}

.skillsRight{
    position: relative;
    /* background-color: orange; */
    height: 100px;
    width: 90%;
    /* height: 40vh; */
}

.skillsHeading{
    position: relative;
    font-family: 'Open Sans';
    font-weight: 900;
    font-size: 20px;
}

.skillsContainer ul{
    color: black;
    font-family: 'Barlow';
    display: block;
    /* margin-top: 1%; */
    padding-left: 0%;
    list-style-type: none;
    /* display: flex; */
}

.skillsContainer li{
    padding-top: 1%;
    padding-bottom: 1%;
    display: inline-block;
    /* background-color: aqua; */
    width: 100%;
    margin-bottom: 5%;
    margin-right: 10%;
    transition: 0.2s;
}

.skillsContainer li:hover{
    background-color: lightgray;
    transition: 0.2s;
    /* transform: scale(1.1);  */
}

.barContainer{
    width: 50%;
    background-color: grey;
    float: right;
    display:inline-block;
   
}

.skillHTML{width: 90%; background-color: rgb(16, 175, 159)}
.skillCSS{width: 95%; background-color: rgb(16, 175, 159)}
.skillReact{width: 65%; background-color: rgb(16, 175, 159)}


.skillGitHub{width: 90%; background-color: rgb(16, 175, 159)}
.skillLinuxBash{width: 100%; background-color: rgb(16, 175, 159)}
.skillPowershell{width: 75%; background-color: rgb(16, 175, 159)}
.skillAzure{width: 90%; background-color: rgb(16, 175, 159)}
.skillPowerPoint{width: 80%; background-color: rgb(16, 175, 159)}
.skillExcel{width: 100%; background-color: rgb(16, 175, 159)}
.skillWord{width: 95%; background-color: rgb(16, 175, 159)}
.skillCircuitry{width: 90%; background-color: rgb(16, 175, 159)}
.skillSoldering{width: 70%; background-color: rgb(16, 175, 159)}
.skillCAD{width: 90%; background-color: rgb(16, 175, 159)}
.skill3DPrinting{width: 95%; background-color: rgb(16, 175, 159)}
.skillCommunication{width: 88%; background-color: rgb(16, 175, 159)}
.skillTeamwork{width: 90%; background-color: rgb(16, 175, 159)}
.skillProblemSolving{width: 90%; background-color: rgb(16, 175, 159)}
.skillTimeManagement{width: 90%; background-color: rgb(16, 175, 159)}
.skillInitiative{width: 95%; background-color: rgb(16, 175, 159)}
.skillLogicGateDesign{width: 90%; background-color: rgb(16, 175, 159)}

/*software*/
.skillPLC{width: 60%; background-color: rgb(16, 175, 159)}
.skillVerilog{width: 90%; background-color: rgb(16, 175, 159)}
.skillC{width: 90%; background-color: rgb(16, 175, 159)}
.skillAutomation{width: 90%; background-color: rgb(16, 175, 159)}
.skillPython{width: 90%; background-color: rgb(16, 175, 159)}
.skillMatlab{width: 80%; background-color: rgb(16, 175, 159)}
.skillScripting{width: 90%; background-color: rgb(16, 175, 159)}

/*controls*/
.skillPID{width: 90%; background-color: rgb(16, 175, 159)}
.skillPredictiveControls{width: 70%; background-color: rgb(16, 175, 159)}
.skillRTSystem{width: 80%; background-color: rgb(16, 175, 159)}
.skillSignalsandSystems{width: 90%; background-color: rgb(16, 175, 159)}
.skillDynamicModels{width: 60%; background-color: rgb(16, 175, 159)}


/*mechanical*/
.skillCAD{width: 95%; background-color: rgb(16, 175, 159)}
.skillFEA{width: 60%; background-color: rgb(16, 175, 159)}
.skillSimulations{width: 90%; background-color: rgb(16, 175, 159)}
.skill3DPrinting{width: 100%; background-color: rgb(16, 175, 159)}
.skillPrototyping{width: 100%; background-color: rgb(16, 175, 159)}
.skillMachining{width: 50%; background-color: rgb(16, 175, 159)}
.skillManufacturing{width: 70%; background-color: rgb(16, 175, 159)}

/*Electronics*/
.skillCircuitDesign{width: 60%; background-color: rgb(16, 175, 159)}
.skillPCBDesign{width: 80%; background-color: rgb(16, 175, 159)}
.skillSensors{width: 95%; background-color: rgb(16, 175, 159)}
.skillSoldering{width: 90%; background-color: rgb(16, 175, 159)}
.skillCrimping{width: 90%; background-color: rgb(16, 175, 159)}
.skillTimingAnalysis{width: 70%; background-color: rgb(16, 175, 159)}


@media screen and (max-width: 768px) {
    .skillsContainer{
        width: 90%;
        /* height: 40vh; */
        margin-bottom: 5%;
    }
    .barContainer{
        width: 100%;
        /* margin-right: 20%; */
        display: flex;
    }
    .skillsColumn{
        height: auto;
    }
}
