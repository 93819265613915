@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100&family=Open+Sans:wght@300&display=swap");
* {
  padding: 0;
  margin: 0;
}


.column{
    display: flex;
    flex-direction: column;
    flex-basis: auto;
    left: 10vw;
    margin-top: 1%;
    align-items: center; 
    justify-items: center;

}
.row {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  /* margin-top: 2%; */
  /* margin-bottom: 2%; */
}

.left-column {
  height: 100px;
  width: 15vw;
  margin-top: 2%;
}

.right-column {
  width: 40vw;
  margin-top: 2%;
  overflow: hidden;
}
.right-column p {
  position: relative;
  top: 5%;
  padding-bottom: 2px;
}

.experienceList{
  margin-top: 5vh;
}

.experienceList ul{
  list-style-type: square;
  margin-left: 10%;
}

#experienceTitle{
    margin-left: 22%;
    color: black;
    font-size: 30px;
    font-family: 'Open Sans';
    font-weight: normal;
    margin-top: 1%;
}

#individualExperiences {
  position: relative;
  color: black;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  font-family: Barlow;
  top: 8%;
  justify-content: center;
}

.SectionContainer h2 {
  position: relative;
  display: flex;

}

.right-column h2{
  font-weight: bold;
}

#degree {
  font-size: 17px;
}
.SectionContainer h3 {
  position: relative;
  top: 15%;
}

.SectionContainer h4 {
  position: relative;
  top: 8%;
}

.SectionContainer h5{
    position: relative;
    display: flex;
    justify-content: center;
    font-family: 'Courier New', Courier, monospace;
}


#location{
    color: green;
    font-weight: 1000;
    padding-bottom: 15px;
}
a{
  text-decoration: none;
}

.left-column img{
    width: 80%;
    height: auto;
    margin-top: 20%;
}

.left-column figure{
    margin-left: 5%;
    margin-right: 5%;
}

@media screen and (max-width: 768px) {
  .right-column{
    display: none;
    width: 0;
  }

  .left-column{
    right: 10vw;
    width: 75vw;
    height: auto;
  }
  .column{
    margin-bottom: 0%;
  }
  #individualExperiences {
    width: 100%;
  }
  .row{
    margin-bottom: 2%;
  } 
  .left-column h3 {
    margin-bottom: 1%;
  }

  .left-column img{
    width: 50%;
    height: auto;
    margin-top: 20%;
  }
  .SectionContainer h3 {
    position: relative;
    top: 5%;
  }

}




