
*{
    font-family: 'Open Sans';
    font-weight: normal; 
}
.banner{
    background-color: lightgray;
    padding-bottom: 2%;
}
.banner h1{
    position: relative;
    text-align: center;
    font-size: 50px;
    font-weight: normal;
    margin-bottom: 2%;
    color: rgb(16, 175, 159);
}

.buttons{
    position: relative;
    justify-content: center;
    display: flex;
}

#button{
    margin-left: 5%;    
    margin-right: 5%;
}

.ProjectContainer{
    height: 100%;
    background-color: whitesmoke;
    width: 90%;
    margin: auto;
    padding: 2%;
}

.ProjectContainer h1{
    font-size: 40px;
    text-align: center;
}

.ProjectContainer h2{
    color: rgb(16, 175, 159);
    margin-left: 10%;
    margin-right: 10%;
}
#externalLinkIconGithub{
    position: relative;
    top: 5px;
}
.ProjectContainer h3{
    color: grey;
    font-size: 15px;
    margin-left: 10%;
    margin-right: 10%;
}

.skillsTab{
    position: relative;
    justify-content: center;
    display: flex;
}
.skillItem{
    margin-left: 2%;
    margin-right: 2%;
    border: 1px solid rgb(16, 175, 159);
    border-radius: 10%;
    padding: 2px;
    font-family: 'Courier New', Courier, monospace;
    
}



.ProjectContainer h4{
    color: black;
    font-weight: bold;
    font-size: 18px;
    margin-top: 2%;
    margin-left: 10%;
    margin-right: 10%;
}
.ProjectContainer h5{
    color: black;
    font-weight: bold;
    font-size: 16px;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 0.5%;
    text-decoration: underline;
}


.ProjectContainer p{
    margin-bottom: 1%;
    margin-left: 10%;
    margin-right: 10%;
}
.ProjectImageContainer{
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
}
.ProjectImageContainer img{
    width: auto;
    height: 150px;
}

.ProjectImageContainer figure{
    margin-left: 1%;
    margin-right: 1%;
    text-align: center;
}


.ProjectImageContainerLarge{
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
}
.ProjectImageContainerLarge img{
    width: auto;
    height: 300px;
}

.ProjectImageContainerLarge figure{
    margin-left: 1%;
    margin-right: 1%;
    text-align: center;
}